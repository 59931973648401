/**
 * Function handles login provided an email address and a password.
 * @param component - Vue component calling the function
 * @param email {string} - Email provided in login form
 * @param password {string} - Password provided in login form.
 * @returns {Promise<void>}
 */
export const loginAction = async (
  component: any,
  email: string,
  password: string
): Promise<void> => {
  /* istanbul ignore next */
  await component.$auth
    .loginWith('local', {
      data: {
        input: {
          email,
          password,
        },
      },
    })
    .then(() => {
      // Forward to projects page if login was successful
      component.$router.push({ path: component.localePath(`/dashboard/`) })
    })
    .catch(() => {
      component.loginError = true
    })
}
