import { getCheckboxValue, getRadioValue } from './getInputValue'

export const inputsValid = (
  component: any,
  fields: string[],
  radios: string[],
  checkboxes: string[]
): boolean => {
  // Checks if there are any form errors in the dom
  const hasFormErrors: boolean = document.getElementsByClassName('form-error').length > 0
  let noRequiredFieldIsEmpty = true
  let noRequiredRadioIsEmpty = true
  let noRequiredCheckboxIsEmpty = true
  // Loops over all provided fields and validates that there is no empty value
  for (const field of fields) {
    // @ts-ignore
    const value = component.$refs[field].$data.value
    if (value === undefined || value.length === 0) {
      noRequiredFieldIsEmpty = false
    }
  }
  // Loops over all provided radios and validates that there is no undefind radio
  for (const radio of radios) {
    const value = getRadioValue(radio)
    if (value.length === 0) {
      noRequiredRadioIsEmpty = false
    }
  }
  // Loops over all provided checkboxes and validates that there is no error
  for (const checkbox of checkboxes) {
    const value = getCheckboxValue(checkbox)
    if (value.length === 0) {
      noRequiredCheckboxIsEmpty = false
    }
  }
  return (
    !hasFormErrors && noRequiredFieldIsEmpty && noRequiredRadioIsEmpty && noRequiredCheckboxIsEmpty
  )
}
