
import matches from 'validator/lib/matches'
import Vue from 'vue'

export default Vue.extend({
  props: {
    placeholder: {
      type: String,
      default: '',
      required: false,
    },
    required: {
      type: Boolean,
      default: false,
      required: false,
    },
    repititionError: {
      type: Boolean,
      default: false,
      required: false,
    },
    complexityCheck: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data() {
    return {
      value: '',
      passwordInputClass: {
        'form-error': false,
      },
      error: '',
    }
  },
  computed: {
    errorMessage(): string {
      const error = this.repititionError
        ? this.$t('passwordInput.repitionError').toString()
        : this.error
      return error
    },
  },
  methods: {
    validInput(): void {
      if (this.value === '' && this.required) {
        this.error = this.$t('global.inputRequiredError').toString()
        this.passwordInputClass['form-error'] = true
      } else if (this.value === '') {
        this.error = ''
        this.passwordInputClass['form-error'] = false
      } else if (
        this.complexityCheck &&
        !matches(this.value, /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,}$/)
      ) {
        this.error = this.$t('passwordInput.complexityError').toString()
        this.passwordInputClass['form-error'] = true
      } else {
        this.error = ''
        this.passwordInputClass['form-error'] = false
      }
    },
  },
})
