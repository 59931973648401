/**
 * Gets the selected value of a radio input
 * @param name {string} - Name of the radio inputs
 */
export const getRadioValue = (name: string): string => {
  const selectedRadio: HTMLInputElement | null = document.querySelector(
    `input[name=${name}]:checked`
  )
  return selectedRadio ? selectedRadio.id : ''
}

/**
 * Gets all checked values of a checkbox input
 * @param name {string} - Name of the checkbox inputs
 */
export const getCheckboxValue = (name: string): string[] => {
  const checkedCheckboxes = document.querySelectorAll(`input[name=${name}]:checked`)
  const checked: string[] = []
  for (const checkbox of checkedCheckboxes) {
    checked.push(checkbox.id)
  }
  return checked
}
