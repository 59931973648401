
import Vue from 'vue'
import isEmail from 'validator/lib/isEmail'

export default Vue.extend({
  name: 'EmailInput',
  props: {
    id: {
      type: String,
      default: '',
      required: false,
    },
    placeholder: {
      type: String,
      default: '',
      required: false,
    },
    required: {
      type: Boolean,
      default: false,
      required: false,
    },
    defaultValue: {
      type: String,
      default: '',
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false,
    },
    checkEmail: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      value: this.$props.defaultValue,
      emailInputClass: {
        'form-error': false,
      },
      error: [false, '', ''],
    }
  },
  methods: {
    /**
     * Validates email input and checks if email is already registered,
     * when checkEmail property is true
     */
    async validateInput(): Promise<void> {
      if (this.validInput()) {
        if (this.checkEmail) {
          await this.isEmailRegistered()
        }
      }
    },
    /**
     * Checks if email is valid
     */
    validInput(): boolean {
      // Checks if email is valid
      if (this.value === '' && this.required) {
        this.error = [true, 'global', 'inputRequiredError']
        this.emailInputClass['form-error'] = true
        return false
      } else if (this.value === '') {
        this.error = [false, '', '']
        this.emailInputClass['form-error'] = false
        return true
      } else if (!isEmail(this.value)) {
        this.error = [true, 'emailInput', 'error']
        this.emailInputClass['form-error'] = true
        return false
      } else {
        this.error = [false, '', '']
        this.emailInputClass['form-error'] = false
        return true
      }
    },
    /**
     * Shows an alert if email is already registered
     */
    async isEmailRegistered(): Promise<void> {
      // Checks if email is already registered
      const email: string = this.$data.value
      const emailIsRegistered: boolean = await this.checkEmailOnAuthService(email)
      // Shows an alert if email is already registered
      if (emailIsRegistered) {
        this.error = [true, 'emailInput', 'emailAlreadyRegisteredError']
        this.emailInputClass['form-error'] = true
      } else {
        this.error = [false, '', '']
        this.emailInputClass['form-error'] = false
      }
    },
    /**
     * Returns true if an email does not exist yet on the auth service.
     */
    async checkEmailOnAuthService(email: string): Promise<boolean> {
      const url = `${this.$config.authServiceBaseUrl}/rest/check-email`
      const response = await this.$axios.$post(url, { email })
      return !response.success
    },
  },
})
